import React from "react";
import { Col, Label, Input } from "reactstrap";
import ParcoursContext from "../../Context/ParcoursContext";
import classes from "./Input.module.css";
function InputComponent({ label, value, name, disabled, type,required }) {
  const { souscripteur, setSouscripteur } = React.useContext(ParcoursContext);
  return (
    <Col xs="12" lg="6" style={{ margin: "0.4rem 0" }}>
      <Label for="input" className={classes.inputTitle}>
        {label}
        {required ? <p style={{ color: "red", paddingInline: "0.2rem" }}> * </p>: ""}
      </Label>
      <Input
        type={type}
        id="input"
        name="nom"
        value={value || ""}
        disabled={disabled}
        onChange={(e) => {
          let tab = [...souscripteur];
          tab[0][name] = e.target.value;
          setSouscripteur(tab);
        }}
      >
        {type === "select" ? (
          <>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
          </>
        ) : null}
      </Input>
    </Col>
  );
}

export default InputComponent;
