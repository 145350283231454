import axios from "axios";
import React from "react";
import ParcoursContext from "./ParcoursContext";

const Context = (props) => {
  const [souscripteur, setSouscripteur] = React.useState([
    {
      nom: "",
      prenom: "",
      dn: "",
      region: "fr",
      adresse: "",
      cp: "",
      ville: "",
      civility: "",
      mail: "",
      phone: "",
      country_prefix: "",
      relationship: "primary subscriber",
      relationship_rank: 1,
      primary_subscriber: true,
      jourprelevement: 5,
      code_insee: "",
    },
  ]);
  const [proposition, setProposition] = React.useState({});
  const [modal, setModal] = React.useState(false);
  const [customerInformations, setCustomerInformations] = React.useState([]);
  const [image, setImage] = React.useState("");
  const [destination, setDestination] = React.useState("");
  const [objetEmail, setObjetEmail] = React.useState("");
  const [email, setEmail] = React.useState(
    `<p style='text-align:center;'><strong>Bonjour,</strong></p>  <p>Suite à notre entretien, je vous transmets notre solution en matière de complémentaire santé.Pour visualiser notre proposition, veuillez cliquer sur le lien suivant :</p>`
  );
  React.useEffect(() => {
    const key = new URLSearchParams(window.location.search).get(
      "partnership_id"
    );
    const apiKey = key ? key : process.env.REACT_APP_API_KEY;
    localStorage.setItem("api_key", apiKey);
    let config = {
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    };
    axios
      .post(`${process.env.REACT_APP_GEOPROD_APIURL}login`, {}, config)
      .then((res) => localStorage.setItem("token", res.data?.Acces_Token))
      .catch((err) => console.log(err));
  }, []);
  return (
    <ParcoursContext.Provider
      value={{
        souscripteur,
        setSouscripteur,
        customerInformations,
        setCustomerInformations,
        modal,
        setModal,
        image,
        setImage,
        destination,
        setDestination,
        objetEmail,
        setObjetEmail,
        email,
        setEmail,
        proposition,
        setProposition,
      }}
    >
      {props.children}
    </ParcoursContext.Provider>
  );
};

export default Context;
