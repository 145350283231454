import React from "react";
import { Row, Col } from "reactstrap";
import classes from "./PreviewEmail.module.css";
/* import { useNavigate } from "react-router-dom"; */

import ParcoursContext from "../../../../Context/ParcoursContext";
function PreviewEmail({ message, url }) {
  const { /* setModal, */ image } = React.useContext(ParcoursContext);
  /*  const navigate = useNavigate(); */
  /*  const RedirectToDevis = () => {
    setModal(false);
    navigate(`${url}${sessionStorage.getItem("idUrl")}`);
  }; */
  return (
    <div style={{ minHeight: "100%" }}>
      <h6> Visuel:</h6>
      <div className={classes.contain}>
        <Row className={classes.header}>
          <Col>
            <h2 className={classes.title}>Devis</h2>
            {image && <img className={classes.head} alt="logo" src={image} />}
          </Col>
        </Row>

        <Row className={classes.rowemail}>
          <Col>
            <p dangerouslySetInnerHTML={{ __html: message }}></p>
          </Col>
        </Row>
        {/* <Row className={classes.rowbtn}>
          <Col className={classes.btn}>
            <Button
              size="lg"
              block
              style={{ backgroundColor: "red" }}
              onClick={RedirectToDevis}
            >
              {/* <img
                src={icon}
                alt="icon"
                style={{
                  height: "1rem",
                  width: "1.5rem",
                  paddingRight: "0.5rem",
                  marginBottom: "0.22rem",
                }}
              /> 
              DEVIS
            </Button>
          </Col>
        </Row> */}
        <Row className={classes.rowtext}>
          <Col className={classes.text}>
            <p>
              Je reste à votre entière disposition pour tout complément
              d'informations. Je vous remercie de la confiance que vous nous
              témoignez, en espérant avoir répondu à vos attentes. Cordialement,
            </p>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default PreviewEmail;