import React, { useEffect, useState } from 'react';
import classes from './EditorText.module.css';
import { Editor } from 'react-draft-wysiwyg';

import './ReactDraft.css';
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from 'draft-js';
import { Label, Input, Row, Col } from 'reactstrap';
import draftToHtml from 'draftjs-to-html';
import ParcoursContext from '../../../../Context/ParcoursContext';

function EditorText(props) {
  const { message } = props;
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const { destination, setDestination, objetEmail, setObjetEmail } =
    React.useContext(ParcoursContext);
  const onEditorStateChange = (newState) => {
    setEditorState(newState);
    let mes = draftToHtml(convertToRaw(newState.getCurrentContent()));
    props.onChange(mes);
    // setEmail(mes);
  };

  useEffect(() => {
    setEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(message))
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ minHeight: '100%' }}>
      <Row>
        <h5>Détails email</h5>
      </Row>

      <Row className={classes.rowadresse}>
        <Col xs='12' lg='4'>
          <Label for='exampleEmail'>Adresse mail de l'assuré:</Label>
        </Col>
        <Col xs='12' lg='8'>
          <Input
            value={destination}
            type='email'
            name='email'
            id='exampleEmail'
            onChange={(e) => setDestination(e.target.value)}
          />
        </Col>
      </Row>
      <Row className={classes.sujet}>
        <Col xs='12' lg='4'>
          <Label for='sujet'>Sujet de l'email:</Label>
        </Col>
        <Col xs='12' lg='8'>
          <Input
            value={objetEmail}
            name='sujet'
            id='sujet'
            onChange={(e) => setObjetEmail(e.target.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs='12' lg='4'>
          <Label for='text'> Text: </Label>
        </Col>
        <Col
          style={{
            backgroundColor: 'white',
            minHeight: '20rem',
            borderRadius: '0.9rem',
            boxSizing: 'border-box',
            border: '1px solid #e6d3b3',
          }}
        >
          <Editor
            editorState={editorState}
            toolbarClassName={classes.toolbarClassName}
            wrapperClassName={classes.wrapperClassName}
            editorClassName={classes.editorClassName}
            onEditorStateChange={onEditorStateChange}
          />
        </Col>
      </Row>
    </div>
  );
}

export default EditorText;
