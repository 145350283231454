import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import ParcoursContext from "../../Context/ParcoursContext";
import classes from "./Success.module.css";
function Success() {
  const { souscripteur } = React.useContext(ParcoursContext);

  const id = localStorage.getItem("api_key");
  return (
    <>
      <div className={classes.container}>
        <Row className={classes.box}>
          <Col className={classes.felicitation}>
            Félicitation{" "}
            {souscripteur[0]?.civility === "Monsieur" ? "Mr" : "Mme"}{" "}
            {souscripteur[0].nom} {souscripteur[0].prenom}
          </Col>
          <Col className={classes.icon}>
            <svg
              width="50"
              height="50"
              viewBox="0 0 110 95"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M104.358 33.6834C102.209 31.1881 99.553 29.1871 96.57 27.8161C93.587 26.445 90.3465 25.7358 87.0678 25.7365H68.7881L70.3279 16.3121C70.8727 12.9933 70.2154 9.5877 68.4759 6.71652C66.7364 3.84534 64.0307 1.69988 60.8521 0.671447C57.6736 -0.356982 54.2341 -0.199846 51.1609 1.11419C48.0878 2.42823 45.5858 4.81162 44.1113 7.82963L35.3404 25.7365H22.9126C16.838 25.7439 11.0143 28.1787 6.71898 32.5069C2.42361 36.8351 0.00727638 42.7033 0 48.8244L0 71.9122C0.00727638 78.0332 2.42361 83.9014 6.71898 88.2296C11.0143 92.5579 16.838 94.9927 22.9126 95H83.86C89.3749 94.9773 94.6985 92.9605 98.8623 89.3165C103.026 85.6726 105.753 80.6436 106.548 75.1445L109.779 52.0567C110.234 48.7816 109.985 45.4462 109.05 42.2763C108.115 39.1064 106.514 36.176 104.358 33.6834ZM9.16503 71.9122V48.8244C9.16503 45.1504 10.6134 41.6269 13.1916 39.029C15.7698 36.4311 19.2665 34.9717 22.9126 34.9717H32.0776V85.7649H22.9126C19.2665 85.7649 15.7698 84.3054 13.1916 81.7075C10.6134 79.1096 9.16503 75.5861 9.16503 71.9122ZM100.682 50.7591L97.4472 73.8469C96.9742 77.1438 95.3426 80.1601 92.8491 82.3473C90.3556 84.5345 87.1659 85.7472 83.86 85.7649H41.2426V33.7434C41.6745 33.3643 42.032 32.9069 42.2966 32.3951L52.3278 11.9162C52.7039 11.2324 53.2358 10.6484 53.8795 10.2121C54.5233 9.7758 55.2606 9.49964 56.0309 9.40634C56.8012 9.31303 57.5825 9.40525 58.3105 9.6754C59.0385 9.94555 59.6926 10.3859 60.2188 10.9603C60.6691 11.4879 60.9982 12.109 61.1828 12.7795C61.3673 13.45 61.4028 14.1532 61.2866 14.8391L58.867 29.6153C58.7608 30.2755 58.7982 30.9511 58.9766 31.5953C59.1551 32.2396 59.4702 32.837 59.9004 33.3464C60.3305 33.8558 60.8653 34.265 61.4678 34.5456C62.0704 34.8262 62.7262 34.9716 63.3899 34.9717H87.0678C89.0356 34.9714 90.9804 35.3968 92.771 36.2191C94.5615 37.0414 96.156 38.2414 97.4467 39.7381C98.7374 41.2347 99.6942 42.9932 100.252 44.8945C100.811 46.7958 100.957 48.7958 100.682 50.7591Z"
                fill="#254484"
              />
            </svg>
          </Col>
          <Col className={classes.souscriptionTerminée}>
            Votre souscription est terminé
          </Col>
          <Col>
            <Button
              onClick={() => {
                sessionStorage.clear();
                id
                  ? (window.location = `/?partnership_id=${id}`)
                  : (window.location = "/");
              }}
              className={classes.btn}
            >
              Une autre souscription
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Success;
