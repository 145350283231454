/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import classes from "./SendEmail.module.css";
import { Row, Col, Container } from "reactstrap";

import { Button } from "react-bootstrap";
import axios from "axios";

import Swal from "sweetalert2";
import EditorText from "./EditorText/EditorText";
import PreviewEmail from "./PreviewEmail/PreviewEmail";
import ParcoursContext from "../../../Context/ParcoursContext";
function SendEmail(props) {
  const { modal, SendEmail, setModal, saveToSession, devisDownload } = props;
  //eslint-disable-next-line
  const { destination, objetEmail } = React.useContext(ParcoursContext);
  const { souscripteur } = React.useContext(ParcoursContext);
  React.useEffect(() => {
    if (saveToSession) {
      saveToSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [message, setMessage] = useState(
    `<p style='text-align:center; color:black'><strong>Bonjour  ${souscripteur[0]?.prenom} ${souscripteur[0]?.nom},</strong></p>   <p>Suite à notre entretien, je vous transmets notre solution en matière de protection juridique avec notre produit Sérénité.   Pour visualiser notre proposition, veuillez cliquer sur le lien suivant :   </p>`
  );
  const sendEmail = () => {
    let emailHeader = `
   <div style="
   background-color: #13274F;
   height: rem;
   padding: 1.25rem;
  
   
   position:relative;
">
   <h2 style="
   color: white;
    text-align: center;
    position: absolute;
    top: 50%;  
    left: 50%; 
    transform: translate(-50%,-50%);
    font-weight:666 ;
    font-size:1rem;
   ">Devis</h2>
 
  </div> 
    `;
    let emailFooter = `<div
    style="
    margin-left: auto;
    margin-right: auto;
      align-items: center;
    "
  >
    <div
      id="link_det"
      align="center"
      style="
        padding-right: 40px;
        padding-left: 40px;
        padding: 15px;
        color: #ffffff;
        text-decoration: none;
        text-transform: uppercase;
        background-color: #e8291b;
        border-radius: 6px;
        font-family:Arial, Helvetica, sans-serif;
      "
    >
    <img src="https://sdn.as-solution.cloud.geoprod.com/static/uploads/cmp/908499056/1664187072.75361__arrow.png" style="height:0.80rem; width:1.10rem; padding-right:0.5rem ">
      <a
        href="${props.devis}"
        style="
          color: #ffffff;
          text-decoration: none;
          text-transform: uppercase;
          letter-spacing: 4px;
          display: inline-block;
        "
        >DEVIS</a
      >
    </div>
    <div
      style="
        padding: 35px;
        padding-top: 0px;
        color: #515151;
        font-family: 'Chivo', sans-serif;
      "
    >
      <p
        style="text-align: center; line-height: 35px"
        id="conforme_sante"
      ></p>
      <p style="text-align: center; line-height: 35px">
        Je reste à votre entière disposition pour tout complément
        d'informations.<br />
        Je vous remercie de la confiance que vous nous témoignez, en espérant
        avoir répondu à vos attentes.<br />Cordialement,
      </p>
    </div>
  </div>`;
    let element = `<div  >
         ${emailHeader}
         ${message}
         ${emailFooter}
    </div>`;
    let lienRepriseAdhesion = sessionStorage.getItem("repriseAdhesion");
    axios
      .post(
        `${process.env.REACT_APP_GEOPROD_APIURL}/send_mail_prospect_new`,
        {
          adresse_mail: destination,
          subject: objetEmail,
          message: element,
          id_prospect: "",
          id_opp: sessionStorage.getItem("SessionURLID"),
          active: 0,
          lien: `${process.env.REACT_APP_FRONTOFFICE_PREPROD}/Proposition/InformationBancaires?IdAdhesion=${lienRepriseAdhesion}`,
          // files: [props.devis],
        },
        {
          headers: {
            idSession: `${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.error === false) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "E-mail envoyé avec succès !",
            showConfirmButton: false,
            timer: 1500,
          });
          setModal(!modal);
        } else {
          Swal.fire({
            icon: "error",
            title: "Erreur...",
            text: res.data?.messsage,
          });
        }
      })
      .catch((err) => {
        // Swal.fire({
        //   icon: 'error',
        //   title: 'Oops...',
        //   text: "Quelque chose s'est mal passé !",
        // });
        console.log(err);
      });
  };
  return (
    <>
      <Modal show={modal} fullscreen onHide={SendEmail}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Container fluid className={classes.contain}>
            <Row>
              <Col xs="12" lg="6">
                <EditorText message={message} onChange={setMessage} />
              </Col>
              <Col xs="12" lg="6">
                <PreviewEmail message={message} />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={sendEmail}>
            Envoyer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SendEmail;
