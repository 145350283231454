import React from 'react';
// import { makeStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';

import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';

import { useNavigate } from 'react-router-dom';
import ParcoursContext from '../../Context/ParcoursContext';
import moment from 'moment/moment';
import axios from 'axios';
import classes from './Proposition.module.css';
import SendEmail from './SendEmail/SendEmail';
import SessionStorageService from '../../utils/sessionStorageService';
import { Col, Row } from 'reactstrap';
import { Alert, Backdrop, Snackbar } from '@mui/material';
import pdfIcon from '../../assets/images/pdf.png';
import mailIcon from '../../assets/images/mail.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';
import Card from '../../components/Card/Card';

export default function Proposition(props) {
  const [checked, setChecked] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [openf, setOpenf] = React.useState(false);
  const [openbackdrop, setOpenbackdrop] = React.useState(false);

  const [idUrl, setIdUrl] = React.useState(sessionStorage.getItem('idUrl'));
  const navigate = useNavigate();
  const [propositions, setPropositions] = React.useState({ data: [] });

  const {
    souscripteur,
    setCustomerInformations,
    customerInformations,
    proposition,
  } = React.useContext(ParcoursContext);

  const [pdf, setPdf] = React.useState();
  React.useEffect(() => {
    // let products = Array(souscripteur.length).fill([
    //   parseInt(process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT),
    // ]);
    let customerInformations = [];
    souscripteur.forEach((val) => {
      customerInformations.push({
        number: 476819 + val?.relationship_rank,
        primary_subscriber: val?.primary_subscriber,
        insured: true,
        beneficiary: false,
        birth_info: {
          date: moment(val.dn, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        },
        address: {
          zip_code: souscripteur[0]?.cp,
          code_insee: souscripteur[0]?.code_insee,
        },
        job: {
          csp_insee: 'Toutes',
          regime: 'Régime général',
          number_yes: 0,
          force_yes: false,
        },
        contract_information: {
          number: 476820,
        },
        relationship: val?.relationship,
        relationship_rank: val?.relationship_rank,
      });
    });
    let productsIds = {};

    for (let i = 0; i < customerInformations.length; i++) {
      productsIds[0] = Array(customerInformations.length).fill([
        parseInt(process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT),
      ]);
      productsIds[1] = Array(customerInformations.length).fill([
        process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT_SIMPLE,
      ]);
    }

    axios
      .post(
        `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/pricing/get_mass_pricing`,
        {
          business_introducer: {
            login: '2556',
          },
          third_app: {
            name: 'ASolutions',
            id_external: 476820,
          },
          customer_information: customerInformations,
          contract_information: {
            effective_date: '01/01/2022',
            mode_splitting: 'Mensuel',
          },
          products: productsIds,
        }
      )
      .then((res) => {
        if (res.data?.data[0]?.success === false) {
        } else {
          setPropositions(res.data);
          setCustomerInformations(customerInformations);
        }
      })
      .catch((err) => console.log(err));
    var listProspects = [];

    souscripteur
      .filter((element) => element?.relationship_rank >= 2)
      .forEach((element) => {
        listProspects = [
          ...listProspects,
          {
            name: element?.nom,
            surname: element?.prenom,
            DN: element?.dn,
            id: '',
            id_opp: '',
            campagne_id: '',
            post: '',
            interlocuteur: '',
            ville: '',
            situation: element?.relationship === 'married' ? 'married' : '',
            subs_tag:
              element?.relationship_rank === 2
                ? 'Conjoint'
                : 'Enfant' + (element?.relationship_rank - 10),
            users_nom: '',
            users_prenom: '',
            adresse_mail: '',
            civilite: '',
            prospects_civ: '',

            prospects_situation: '',
            nb_enfants: '',
            adresses_adresse: '',
            streetNumber: '',
            CP: '',
            streetName: '',
            adresses_ville: '',
            complement_adr: '',
            tel: '',
            tel2: '',
            mobile: '',
            first_campagne: '',
            description: '',
            etat: '',
            lat: '',
            latitude: null,
            longitude: null,
            commercieux_id: '',
            commentaire: '',
            geom: '',
            tags: [],
            lng: '',
            date_naiss: '',
            collab: '',
            id_pros: '',
            id_camp: '',
            coment_plus: '',
            code: '',
            rue: '',
            numero: '',
            complement: '',
            mail: '',
            nb_enfant: '',
            infcomp: '',
            relation:
              element?.relationship === 'married' ? 'Conjoint' : 'Descendant',
            pays: '',
            invalidForms: [],
            deleted: false,
            information_bancaire: {
              id: '',
              titulaire_compte: '',
              iban: '',
              bic_swift: '',
              type_iban: '',
              nom_banque: '',
            },
            prospects_id: '',
          },
        ];
      });

    let offre = {
      info_specifiques_campagnes: {},
      info_specifiques_ligne_produit: {},
      prospects: {
        listProspects: listProspects,
        mainProspect: {
          interlocuteur: null,
          tags: [],
          invalidForms: [],
          id: null,
          prospects_id: null,
          post: null,
          ville: souscripteur[0]?.ville,
          situation: null,
          adresse_mail: souscripteur[0]?.mail,
          civilite: souscripteur[0]?.civility,
          name: souscripteur[0]?.prenom,
          surname: souscripteur[0]?.nom,
          DN: souscripteur[0]?.dn,
          nb_enfants:
            souscripteur[1]?.relationship === 'married'
              ? souscripteur.length - 2
              : souscripteur.length - 1,
          streetNumber: null,
          longitude: null,
          latitude: null,
          CP: souscripteur[0]?.cp,
          streetName: null,
          complement_adr: null,
          tel: souscripteur[0]?.phone,
          tel2: null,
          mobile: null,
          description: null,
          relation: 'principal',
          subs_tag: 'Prospect',
        },
      },
    };
    axios
      .post(`${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/offre/add`, offre, {
        headers: {
          idSession: localStorage.getItem('token'),
        },
      })
      .then((res) => {
        sessionStorage.setItem('SessionURLID', res.data?.id_opportunite_md5);
        sessionStorage.setItem('id_prospect', res.data?.id_prospect_md5);
        sessionStorage.setItem(
          '_subs_tags',
          JSON.stringify(res.data?._subs_tags)
        );
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const createHistory = () => {
    let newCustomerInformations = [];
    customerInformations.forEach((element, index) => {
      newCustomerInformations.push({
        number: element?.relationship_rank,
        primary_subscriber: element?.primary_subscriber,
        insured: true,
        beneficiary: false,
        birth_info: {
          date: element?.birth_info.date,
        },
        address: {
          street_name: souscripteur[0]?.adresse,
          zip_code: souscripteur[0]?.cp,
          city: souscripteur[0]?.ville,
        },
        job: {
          csp_insee: 'Toutes',
          regime: 'Régime général',
          number_yes: 0,
          force_yes: false,
        },
        contract_information: {
          number: 476820,
          product:
            proposition?.product_range === 'PJ VIE PRIVE' ? [1140] : [1141],
        },
        relationship: element?.relationship,
        relationship_rank: element?.relationship_rank,
        mail: souscripteur[0]?.mail,
        identity: {
          civility: souscripteur[0]?.civility === 'mme' ? 'Madame' : 'Monsieur',
          // element?.relationship_rank === 1
          //   ? "Monsieur"
          //   : element?.relationship_rank === 2
          //   ? "Madame"
          //   : "Monsieur",
          use_name: souscripteur[index]?.nom,
          first_name: souscripteur[index]?.prenom,
        },
        //data[35]['num_sec_assure||N° sécurité sodiale||6'] without last 2 digits
        social_security_number: '1980506000000',

        social_security_number_link: '13',
        phone: [
          {
            type: 'Mobile',
            country_prefix: souscripteur[0]?.country_prefix,
            number: souscripteur[0]?.phone,
          },
        ],
      });
    });

    let repriseAdhesion = sessionStorage.getItem('id_prospect') + Date.now();
    sessionStorage.setItem('repriseAdhesion', repriseAdhesion);
    axios.post(
      `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/session_url/set_object_from_session_url`,
      {
        id: repriseAdhesion,
        objet: {
          proposition: proposition,
          customerInfo: newCustomerInformations,
          souscripteur: souscripteur,
        },
      }
    );
  };

  const uploadDevisOpportunite = async (file_url) => {
    const response = await fetch(file_url);
    const blob = await response.blob();

    let f = new FormData();
    f.append('file[]', blob, 'devis.pdf');
    f.append('id_pros', sessionStorage.getItem('id_prospect'));
    f.append('id_document', 117);
    f.append('id_opp', sessionStorage.getItem('SessionURLID'));
    f.append('name_doc', 'devis_');

    axios
      .post(
        `${process.env.REACT_APP_GEOPROD_APIURL}upload_document_opportunite`,
        f,
        {
          headers: {
            idSession: localStorage.getItem('token'),
          },
        }
      )
      .then((res) => {
        setOpenbackdrop(false);
      })
      .catch((err) => {
        setOpenbackdrop(false);
        console.log(err);
      });
  };
  const sendEmail = async () => {
    if (proposition.product_range) {
      setOpenbackdrop(true);
      axios
        .post(
          process.env.REACT_APP_EDITIQUE + '/editique_pdf/',
          {
            idModel: process.env.REACT_APP_DEVIS_MODEL,
            data: {
              nom_adherent: souscripteur[0]?.nom,
              prenom_adherent: souscripteur[0]?.prenom,
              date_naissance: moment(souscripteur[0]?.dn).format('DD-MM-YYYY'),
              lieu_naissance: souscripteur[0]?.adresse,
              code_postal: souscripteur[0]?.cp,
              ville: souscripteur[0]?.ville,
              adresse: souscripteur[0]?.adresse,
              civilite: souscripteur[0]?.civility,

              cot_an: proposition.total_yearly.toString(),
              cot_men: proposition.total_monthly.toString(),

              email: souscripteur[0]?.mail,
              // mail: souscripteur[0]?.mail,
              num_devis: Date.now().toString(),
              num_telephone:
                souscripteur[0]?.country_prefix + souscripteur[0]?.phone,
              opt_premium: proposition.number === '1' ? 'non' : 'oui',
            },
          },
          {
            headers: {
              apiKey: process.env.REACT_APP_EDITIQUE_APIKEY,
            },
          }
        )
        .then((res) => {
          setPdf(res.data?.file_url);
          uploadDevisOpportunite(res.data?.file_url);
          setOpenbackdrop(false);
          const idSessionURL = Date.now() + SessionStorageService().getId();
          sessionStorage.setItem('idUrl', idSessionURL);
          setIdUrl(idSessionURL);
          if (modal === false) {
            createHistory();
            setModal(!modal);
          }
          setModal(!modal);
        });
    } else {
      setOpenf(true);
    }
  };

  const devisDownload = async () => {
    if (proposition.product_range) {
      setOpenbackdrop(true);
      axios
        .post(
          process.env.REACT_APP_EDITIQUE + '/editique_pdf/',
          {
            idModel: process.env.REACT_APP_DEVIS_MODEL,
            data: {
              nom_adherent: souscripteur[0]?.nom,
              prenom_adherent: souscripteur[0]?.prenom,
              date_naissance: moment(souscripteur[0]?.dn).format('DD-MM-YYYY'),
              lieu_naissance: souscripteur[0]?.adresse,
              code_postal: souscripteur[0]?.cp,
              ville: souscripteur[0]?.ville,
              adresse: souscripteur[0]?.adresse,
              civilite: souscripteur[0]?.civility,

              cot_an: proposition?.total_yearly.toString(),
              cot_men: proposition?.total_monthly.toString(),

              email: souscripteur[0]?.mail,
              mail: souscripteur[0]?.mail,
              num_devis: Date.now().toString(),
              num_telephone:
                souscripteur[0]?.country_prefix + souscripteur[0]?.phone,
              opt_premium: proposition?.number === '1' ? 'non' : 'oui',
            },
          },
          {
            headers: {
              apiKey: process.env.REACT_APP_EDITIQUE_APIKEY,
            },
          }
        )
        .then((res) => {
          setPdf(res.data?.file_url);
          window.open(res.data?.file_url, '_blank');

          uploadDevisOpportunite(res.data?.file_url);
        });
    } else {
      setOpenf(true);
    }
  };
  return (
    <section className={classes.section}>
      <SendEmail
        SendEmail={sendEmail}
        modal={modal}
        idURL={idUrl}
        setModal={setModal}
        url={'/devis/'}
        devisDownload={devisDownload}
        devis={pdf}
      />

      <Container
        maxWidth='lg'
        style={{ padding: '2rem', alignItems: 'center' }}
      >
        <Row>
          <Col className={classes.propositionCol}>
            <p className={classes.propositionText}>Proposition d'assurance</p>
          </Col>
        </Row>
        <Row>
          <Col className={classes.tarificationCol}>
            <p className={classes.tarificationText}>
              Nous vous avons déjà envoyé un e-mail contient le devis de
              tarification
            </p>
          </Col>
        </Row>
        <div className={classes.box}>
          <Row>
            <Col className={classes.switchComponent}>
              <p
                className={
                  checked === true
                    ? classes.mensuelle
                    : classes.mensuelleChecked
                }
              >
                Mensuelle
              </p>
              <Switch
                color='primary'
                checked={checked}
                onChange={() => setChecked(!checked)}
              />
              <p
                className={
                  checked === true ? classes.annuelle : classes.annuelleChecked
                }
              >
                Annuelle
              </p>
            </Col>
          </Row>
          <Row>
            {' '}
            <Col>
              <p className={classes.garantieChoix}>
                choisissez la garantie qui vous convient
              </p>
            </Col>
          </Row>
          <Row className={classes.propositionBoxs}>
            {propositions.data.map((val, index) => {
              return proposition.product_range === val.product_range ? (
                <Card
                  key={index}
                  index={index}
                  val={val}
                  selected={true}
                  checked={checked}
                />
              ) : (
                <Card
                  key={index}
                  index={index}
                  val={val}
                  selected={false}
                  checked={checked}
                />
              );
            })}
          </Row>
          <Row>
            <Col style={{ display: 'flex' }}>
              <Button className={classes.buttons} onClick={sendEmail}>
                {' '}
                <img
                  src={mailIcon}
                  alt='mail'
                  style={{ marginRight: '0.5rem' }}
                />{' '}
                Envoyer E-mail
              </Button>

              <Button className={classes.buttons} onClick={devisDownload}>
                {' '}
                <img
                  src={pdfIcon}
                  alt='pdf'
                  style={{ marginRight: '0.5rem' }}
                />{' '}
                Exporter PDF
              </Button>
            </Col>
          </Row>
        </div>

        <Row style={{ marginTop: '2rem' }}>
          <Col className={classes.RetourCol}>
            <Button
              className={classes.retourButton}
              onClick={() => navigate('/')}
            >
              {' '}
              <FontAwesomeIcon
                style={{ marginRight: '0.5rem' }}
                icon={faArrowLeft}
              />
              Retour
            </Button>
          </Col>
          <Col>
            <Button
              className={classes.confirmButton}
              onClick={() => {
                if (proposition.product_range) {
                  navigate('/Proposition/InformationBancaires', {
                    state: {
                      proposition: proposition,
                    },
                  });
                } else {
                  setOpenf(true);
                }
              }}
            >
              Confirmer{' '}
              <FontAwesomeIcon
                style={{ marginLeft: '0.5rem' }}
                icon={faArrowRight}
              />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Snackbar
              open={openf}
              autoHideDuration={3000}
              onClose={() => setOpenf(false)}
            >
              <Alert
                onClose={() => setOpenf(false)}
                severity='error'
                sx={{ width: '100%' }}
              >
                Vous devez choisir au moins une proposition
              </Alert>
            </Snackbar>
          </Col>
        </Row>
      </Container>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openbackdrop}
        /* onClick={handleCloseBackdrop} */
      >
        <div className={classes.loaderContainer}>
          <Box sx={{ width: '100%' }}>
            <CircularProgress style={{ width: '4rem', height: '4rem' }} />
          </Box>
          <h2>Veuillez patienter un instant.</h2>
        </div>
      </Backdrop>
    </section>
  );
}
