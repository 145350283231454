import React from 'react';
import { Row, Col, Form, Button } from 'reactstrap';
import { Alert, Snackbar, Backdrop } from '@mui/material';
import classes from './InformationsBancaires.module.css';
import IBAN from 'iban';
import ParcoursContext from '../../Context/ParcoursContext';
import bicValidator from 'bic-validator';
import axios from 'axios';
import moment from 'moment/moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';
import InputComponent from '../../components/Input/InputComponent';
import Swal from 'sweetalert2';

function InformationsBancaires() {
  const {
    souscripteur,
    setSouscripteur,
    customerInformations,
    setCustomerInformations,
  } = React.useContext(ParcoursContext);
  const navigate = useNavigate();
  const params = useLocation();
  const [proposition, setproposition] = React.useState(params?.state);
  const [openBic, setOpenBic] = React.useState(false);
  const [openIban, setOpenIban] = React.useState(false);
  const [openbackdrop, setOpenbackdrop] = React.useState(false);
  const rows = [
    [
      {
        label: 'Nom et Prénom du débiteur',
        value: souscripteur[0]?.nom + ' ' + souscripteur[0]?.prenom,
        disabled: true,
        name: 'nom',
        type: 'text',
      },
      {
        label: 'Adresse',
        value: souscripteur[0]?.adresse,
        disabled: true,
        name: 'adresse',
        type: 'text',
      },
    ],
    [
      {
        label: 'Ville',
        value: souscripteur[0]?.ville,
        disabled: true,
        name: 'ville',
        type: 'text',
      },
      {
        label: 'Code postal',
        value: souscripteur[0]?.cp,
        disabled: true,
        name: 'cp',
        type: 'text',
      },
    ],
    [
      {
        label: 'BIC prélèvement',
        value: souscripteur[0]?.bic,
        disabled: false,
        name: 'bic',
        type: 'text',
        required: true,
      },
      {
        label: 'IBAN prélèvement',
        value: souscripteur[0]?.iban,
        disabled: false,
        name: 'iban',
        type: 'text',
        required: true,
      },
    ],
    [
      {
        label: 'BIC remboursement',
        value: souscripteur[0]?.bicremboursement,
        disabled: false,
        name: 'bicremboursement',
        type: 'text',
      },
      {
        label: 'IBAN remboursement',
        value: souscripteur[0]?.ibanremboursement,
        disabled: false,
        name: 'ibanremboursement',
        type: 'text',
      },
    ],
    [
      {
        label: 'Jour de prélèvement',
        value: souscripteur[0]?.jourprelevement,
        disabled: false,
        name: 'jourprelevement',
        type: 'select',
      },
      {
        label: 'Nom de la banque prélèvement',
        value: souscripteur[0]?.banquePrelevement,
        disabled: false,
        name: 'banquePrelevement',
        type: 'text',
      },
    ],
    [
      {
        label: 'Nom de la banque remboursement',
        value: souscripteur[0]?.banqueRemboursement,
        disabled: false,
        name: 'banqueRemboursement',
        type: 'text',
      },
    ],
  ];

  const [nomDebiteur, setNomDebiteur] = React.useState(
    souscripteur[0]?.nom + ' ' + souscripteur[0]?.prenom
  );
  function isValidIBan() {
    return IBAN.isValid(souscripteur[0]?.iban);
  }

  function isValidBic() {
    return bicValidator.isValid(souscripteur[0]?.bic);
  }

  React.useEffect(() => {
    let id_pros = JSON.parse(
      sessionStorage.getItem('_subs_tags')
    ).id_prospect_md5_with_givenTgas;

    const id = new URLSearchParams(window.location.search).get('IdAdhesion');
    if (id) {
      axios
        .get(
          `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/session_url/get_object_from_session_url/${id}`
        )
        .then((res) => {
          if (res.data?.error === true) {
            Swal.fire({
              icon: 'error',
              title: 'Erreur',
              text: res.data?.message.message,
            });
            setOpenbackdrop(false);
          }
          setproposition(res.data?.proposition);
          setCustomerInformations(res.data?.customerInfo);
          setSouscripteur(res.data?.souscripteur);
          setNomDebiteur(
            res.data?.souscripteur[0]?.nom +
              ' ' +
              res.data?.souscripteur[0]?.prenom
          );
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            title: 'Erreur',
            text: err,
          });
          setOpenbackdrop(false);
        });
    } else if (params.state) {
      let newCustomerInformations = [];
      customerInformations.forEach((element, index) => {
        newCustomerInformations.push({
          number: element?.relationship_rank,
          primary_subscriber: element?.primary_subscriber,
          insured: true,
          beneficiary: false,
          birth_info: {
            date: element.birth_info.date,
          },
          address: {
            street_name: souscripteur[0]?.adresse,
            zip_code: souscripteur[0]?.cp,
            city: souscripteur[0]?.ville,
            code_insee: souscripteur[0]?.code_insee,
          },
          id_pros:
            element.relationship_rank === 1
              ? Object.keys(id_pros).find((key) => id_pros[key] === 'Prospect')
              : element.relationship_rank === 2
              ? Object.keys(id_pros).find((key) => id_pros[key] === 'Conjoint')
              : Object.keys(id_pros).find(
                  (key) =>
                    id_pros[key] ===
                    'Enfant' + (element?.relationship_rank - 10)
                ),
          job: {
            csp_insee: 'Toutes',
            regime: 'Régime général',
            number_yes: 0,
            force_yes: false,
          },
          contract_information: {
            number: 476820,
            product:
              proposition?.proposition?.product_range === 'PJ VIE PRIVE'
                ? [process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT_SIMPLE]
                : [process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT],
          },
          relationship: element?.relationship,
          relationship_rank: element?.relationship_rank,
          mail: souscripteur[0]?.mail,
          identity: {
            civility: souscripteur[0]?.civility,
            use_name: souscripteur[index]?.nom,
            first_name: souscripteur[index]?.prenom,
          },
          phone: [
            {
              type: 'Mobile',
              country_prefix: souscripteur[0]?.country_prefix,
              number: souscripteur[0]?.phone,
            },
          ],
        });
      });
      setCustomerInformations(newCustomerInformations);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const subscribeHandler = async () => {
    setOpenbackdrop(true);
    var files = [];
    var listProspects = [];

    if (souscripteur.length > 1) {
      souscripteur
        .filter((element) => element?.relationship_rank >= 2)
        ?.forEach((element) => {
          listProspects = [
            ...listProspects,
            {
              name: element?.nom,
              surname: element?.prenom,
              dn: element?.dn,
              id: '',
              id_opp: '',
              campagne_id: '',
              post: '',
              interlocuteur: '',
              ville: '',
              situation: element?.relationship === 'married' ? 'married' : '',
              users_nom: '',
              users_prenom: '',
              adresse_mail: '',
              civilite: '',
              prospects_civ: '',

              prospects_situation: '',
              nb_enfants: '',
              adresses_adresse: '',
              streetNumber: '',
              CP: '',
              streetName: '',
              adresses_ville: '',
              complement_adr: '',
              tel: '',
              tel2: '',
              mobile: '',
              first_campagne: '',
              description: '',
              etat: '',
              lat: '',
              latitude: null,
              longitude: null,
              commercieux_id: '',
              commentaire: '',
              geom: '',
              tags: [],
              lng: '',
              date_naiss: '',
              collab: '',
              id_pros: '',
              id_camp: '',
              coment_plus: '',
              code: '',
              rue: '',
              numero: '',
              complement: '',
              mail: '',
              nb_enfant: '',
              infcomp: '',
              relation:
                element?.relationship === 'married' ? 'Conjoint' : 'Descendant',
              pays: '',
              invalidForms: [],
              deleted: false,
              information_bancaire: {
                id: '',
                titulaire_compte: '',
                iban: '',
                bic_swift: '',
                type_iban: '',
                nom_banque: '',
              },
              prospects_id: '',
            },
          ];
        });
    }
    axios
      .post(
        `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/subscription/subscribe`,
        {
          id_opp: sessionStorage.getItem('SessionURLID'),
          ligne_produit: '14',
          cmp: '908499056',
          information_bancaire: {
            bic_swift: souscripteur[0]?.bicremboursement,
            iban: souscripteur[0]?.ibanremboursement,
            type_iban: 'remboursement',
            titulaire_compte: `${souscripteur[0]?.nom} ${souscripteur[0]?.prenom}`,
            nom_banque: souscripteur[0]?.banqueRemboursement,
          },

          pricing: {
            third_app: {
              name: 'ASolutions',
              id_external: 476820,
            },
            customer_information: customerInformations,
            contract_information: {
              effective_date: moment().add(34, 'days').format('DD/MM/yyyy'),
              mode_splitting: 'Mensuel',
              signature_date: moment().format('DD/MM/yyyy'),

              payment_information: {
                476820: {
                  mode_splitting: 'Mensuel',
                  withdrawal_day: souscripteur[0]?.jourprelevement,
                  payment_mode: 'Direct Debit',
                  bic: souscripteur[0]?.bic,
                  iban: souscripteur[0]?.iban,
                  nom_banque: souscripteur[0]?.banquePrelevement,
                },
              },
            },
          },
        },
        {
          headers: {
            idSession: localStorage.getItem('token'),
          },
        }
      )
      .then((res) => {
        if (res.data?.error === true) {
          Swal.fire({
            icon: 'error',
            title: 'Erreur',
            text: res.data?.message?.message,
          });
          setOpenbackdrop(false);
        }

        let numContrat = res.data?.num_contrat;
        let idAffaire = res.data?.id_affaire;

        let periodeDeb = [];
        let periodFin = [];
        let tabDate = [];
        let cotisation = [];
        res.data?.echeancier?.forEach((element) => {
          periodeDeb.push(element?.period?.from_dt);
          periodFin.push(element?.period?.to_dt);
        });
        res.data?.echeancier?.forEach((element) => {
          tabDate.push(element?.date);
        });
        res.data?.echeancier?.forEach((element) => {
          cotisation.push(element?.amount);
        });

        const periode_echeance = {};
        const date_recapitulatif = {};
        const cot_ttc = {};
        for (let i = 0; i < 12; i++) {
          periode_echeance[`periode_echeance${i + 1}`] =
            moment(periodeDeb[i]).format('DD-MM-YYYY') +
            '  ' +
            moment(periodFin[i]).format('DD-MM-YYYY');
          date_recapitulatif[`date_recapitulatif${i + 1}`] = moment(
            tabDate[i]
          ).format('DD-MM-YYYY');
          cot_ttc[`cot_ttc${i + 1}`] = cotisation[i].toString();
        }
        axios
          .post(
            process.env.REACT_APP_EDITIQUE + '/editique_pdf/',

            {
              idModel: process.env.REACT_APP_BA_MODEL,
              data: {
                num_bulletin: res.data?.num_contrat,
                nom_adherent: souscripteur[0]?.nom,
                prenom_adherent: souscripteur[0]?.prenom,
                date_naissance: moment(souscripteur[0]?.dn).format(
                  'DD-MM-YYYY'
                ),
                lieu_naissance: souscripteur[0]?.adresse,
                code_postal: souscripteur[0]?.cp,
                ville: souscripteur[0]?.ville,
                adresse: souscripteur[0]?.adresse,
                bic_remb: souscripteur[0]?.bic,
                iban_remb: souscripteur[0]?.bic,
                bic: souscripteur[0]?.bic,
                iban: souscripteur[0]?.bic,
                nom_prenom_debiteur: nomDebiteur,
                code_postal_debiteur: souscripteur[0]?.cp,
                ville_debiteur: souscripteur[0]?.ville,
                adresse_debiteur: souscripteur[0]?.adresse,
                cot_men: proposition?.proposition?.total_monthly.toString(),
                cot_ann: proposition?.proposition?.total_yearly.toString(),
                civilite: souscripteur[0]?.civility,
                email: souscripteur[0]?.mail,
                num_telephone:
                  souscripteur[0]?.country_prefix + souscripteur[0]?.phone,
                frais_associatif:
                  res.data?.contributions[0]?.details[2]?.annual_amount.toString(),
                frais_quittancement:
                  res.data?.contributions[0]?.details[0]?.annual_amount.toString(),
                CFDP: res.data?.annual_amount_ht.toString(),
                frais_courtage:
                  res.data?.contributions[0]?.details[1]?.annual_amount.toString(),
                taxe_assurance: res.data?.annual_amount.toString(),
                mode_signature: 'Electronique ou papier',
                num_adhesion: res.data?.num_contrat,
                date_echeance: moment(tabDate[0]).format('DD-MM-YYYY'),
                opt_premium:
                  proposition?.proposition?.product_range === 'PJ VIE PRIVE'
                    ? 'non'
                    : 'oui',
                date_signature: moment(Date.now()).format('DD-MM-YYYY'),
                date_effet_originale: moment(Date.now())
                  .add(1, 'days')
                  .format('DD-MM-YYYY'),
                mode_paiement_comptant: 'Prélèvement automatique',
                mode_paiement_cotisation: 'Prélèvement automatique',
                date_prelevement: souscripteur[0]?.jourprelevement,
                ...periode_echeance,
                ...date_recapitulatif,
                ...cot_ttc,
              },
            },
            {
              headers: {
                apiKey: process.env.REACT_APP_EDITIQUE_APIKEY,
              },
            }
          )
          .then(async (res) => {
            if (res.data?.error === true) {
              Swal.fire({
                icon: 'error',
                title: 'Erreur',
                text: res.data?.message?.message,
              });
              setOpenbackdrop(false);
            }
            files = [
              ...files,
              {
                url: res.data?.file_url,
                page: parseInt(process.env.REACT_APP_SIGNATURE_PAGE),
                position: process.env.REACT_APP_SIGNATURE_POSITION_CONTRAT,
                file_name: 'contract',
              },
            ];
            const response = await fetch(res.data?.file_url);
            const blob = await response.blob();

            let f = new FormData();
            f.append('file[]', blob, 'bulletin.pdf');
            f.append('id_pros', sessionStorage.getItem('id_prospect'));
            f.append('id_type_doc', 25);
            f.append('id_affaire', idAffaire);
            f.append('name_doc', 'bulletin_');

            axios
              .post(
                `${process.env.REACT_APP_GEOPROD_APIURL}/upload_document_affaire?`,
                f,
                {
                  headers: {
                    idSession: localStorage.getItem('token'),
                  },
                }
              )
              .then((res) => {
                navigate('/Proposition/InformationBancaires/Success');
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            Swal.fire({
              icon: 'error',
              title: 'Erreur',
              text: err,
            });
            setOpenbackdrop(false);
          });
        axios
          .post(
            process.env.REACT_APP_EDITIQUE + '/editique_pdf/',
            {
              idModel: process.env.REACT_APP_CERTIF_MODEL,
              data: {
                num_certificat: res.data?.num_contrat,
                nom_adherent: souscripteur[0]?.nom,
                prenom_adherent: souscripteur[0]?.prenom,
                date_naissance: moment(souscripteur[0]?.dn).format(
                  'DD-MM-YYYY'
                ),
                lieu_naissance: souscripteur[0]?.adresse,
                code_postal: souscripteur[0]?.cp,
                ville: souscripteur[0]?.ville,
                adresse: souscripteur[0]?.adresse,
                bic_remb: souscripteur[0]?.bic,
                iban_remb: souscripteur[0]?.iban,
                bic: souscripteur[0]?.bic,
                iban: souscripteur[0]?.iban,
                nom_prenom_debiteur: nomDebiteur,
                code_postal_debiteur: souscripteur[0]?.cp,
                ville_debiteur: souscripteur[0]?.ville,
                adresse_debiteur: souscripteur[0]?.adresse,
                cot_men: proposition?.proposition?.total_monthly.toString(),
                cot_ann: proposition?.proposition?.total_yearly.toString(),
                civilite: souscripteur[0]?.civility,
                email: souscripteur[0]?.mail,
                num_telephone:
                  souscripteur[0]?.country_prefix + souscripteur[0]?.phone,
                frais_associatif:
                  res.data?.contributions[0]?.details[2]?.annual_amount.toString(),
                frais_quittancement:
                  res.data?.contributions[0]?.details[0]?.annual_amount.toString(),
                CFDP: res.data?.annual_amount_ht.toString(),
                frais_courtage:
                  res.data?.contributions[0]?.details[1]?.annual_amount.toString(),
                taxe_assurance: res.data?.annual_amount.toString(),
                mode_signature: 'Electronique ou papier',
                num_adhesion: res.data?.num_contrat,
                date_echeance: moment(tabDate[0]).format('DD-MM-YYYY'),
                pack_premium:
                  proposition?.proposition?.product_range === 'PJ VIE PRIVE'
                    ? 'non'
                    : 'oui',
                date_signature: moment(Date.now()).format('DD-MM-YYYY'),
                date_effet_originale: moment(Date.now())
                  .add(1, 'days')
                  .format('DD-MM-YYYY'),
                paiement_comptant: 'Prélèvement automatique',
                paiement_cotisation: 'Prélèvement automatique',
                date_prelevement: souscripteur[0]?.jourprelevement.toString(),
                ...periode_echeance,
                ...date_recapitulatif,
                ...cot_ttc,
              },
            },
            {
              headers: {
                apiKey: process.env.REACT_APP_EDITIQUE_APIKEY,
              },
            }
          )
          .then(async (res) => {
            if (res.data?.error === true) {
              Swal.fire({
                icon: 'error',
                title: 'Erreur',
                text: res.data?.message.message,
              });
              setOpenbackdrop(false);
            }
            // files = [
            //   ...files,
            //   {
            //     url: res.data?.file_url,
            //     page: parseInt(process.env.REACT_APP_SIGNATURE_PAGE),
            //     position: process.env.REACT_APP_SIGNATURE_POSITION_CERTIF,
            //     file_name: 'certificat',
            //   },
            // ];
            const response = await fetch(res.data?.file_url);
            const blob = await response.blob();

            let f = new FormData();
            f.append('file[]', blob, 'certificat.pdf');
            f.append('id_pros', sessionStorage.getItem('id_prospect'));
            f.append('id_type_doc', 30);
            f.append('id_affaire', idAffaire);
            f.append('name_doc', 'certificat_');

            axios
              .post(
                `${process.env.REACT_APP_GEOPROD_APIURL}/upload_document_affaire?`,
                f,
                {
                  headers: {
                    idSession: localStorage.getItem('token'),
                  },
                }
              )
              .then((res) => {})
              .catch((err) => {
                console.log(err);
              });

            let formData = {
              id_opp: sessionStorage.getItem('SessionURLID'),
              num_contrat: numContrat,
              id_affaire: idAffaire,
              files: files,
              data: {
                info_specifiques_campagnes: {
                  budget: null,
                  ex43: null,
                  ex44: null,
                  ex47: null,
                  ex31: null,
                  ex32: null,
                  ex11: null,
                  ex12: null,
                  ex16: null,
                  ex19: null,
                  pb: null,
                  pbo: null,
                  pbob: null,
                  pnr: null,
                },
                info_specifiques_ligne_produit: {
                  Ro: null,
                  Ro_conjoint: null,
                  annee_naissance: null,
                  annee_naissance_conjoint: null,
                  annee_naissance_enfant1: null,
                  annee_naissance_enfant2: null,
                  annee_naissance_enfant3: null,
                  annee_naissance_enfant4: null,
                  annee_naissance_enfant5: null,
                  annee_naissance_enfant6: null,
                  ro_enfant1: null,
                  ro_enfant2: null,
                  ro_enfant3: null,
                  ro_enfant4: null,
                  ro_enfant6: null,
                  situation_pro: '111',
                },
                prospects: {
                  listProspects: listProspects,
                  mainProspect: {
                    interlocuteur: null,
                    tags: [],
                    invalidForms: [],
                    id: null,
                    prospects_id: null,
                    post: null,
                    ville: souscripteur[0]?.ville,
                    situation: null,
                    adresse_mail: souscripteur[0]?.mail,
                    civilite: souscripteur[0]?.civility,
                    name: souscripteur[0]?.prenom,
                    surname: souscripteur[0]?.nom,
                    DN: souscripteur[0]?.dn,
                    nb_enfants:
                      souscripteur[1]?.relationship === 'married'
                        ? souscripteur.length - 2
                        : souscripteur.length - 1,
                    streetNumber: null,
                    longitude: null,
                    latitude: null,
                    CP: souscripteur[0]?.cp,
                    streetName: null,
                    complement_adr: null,
                    tel:
                      souscripteur[0]?.country_prefix + souscripteur[0]?.phone,
                    tel2: null,
                    mobile:
                      souscripteur[0]?.country_prefix + souscripteur[0]?.phone,
                    description: null,
                    relation: 'principal',
                  },
                },
              },
            };
            axios
              .post(
                `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/yousign/e-yousign`,
                formData,
                {
                  headers: {
                    idSession: localStorage.getItem('api_key'),
                    'Access-Control-Allow-Origin': '*',
                  },
                }
              )
              .then((res) => {
                if (res.data?.error === true) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Erreur',
                    text: res.data?.message.message,
                  });
                  setOpenbackdrop(false);
                }
                window.open(res.data?.redirect_url, '_blank');
                setOpenbackdrop(false);
              })
              .catch((err) => {
                Swal.fire({
                  icon: 'error',
                  title: 'Erreur',
                  text: err,
                });
                setOpenbackdrop(false);
              });
            // const computedFileName = `certif.pdf`;
            // const a = document.createElement("a");
            // a.href = window.URL.createObjectURL(blob);
            // a.download = computedFileName;
            // document.body.appendChild(a);
            // a.click();
            // document.body.removeChild(a);
          })
          .catch((err) => {
            Swal.fire({
              icon: 'error',
              title: 'Erreur',
              text: err,
            });
            setOpenbackdrop(false);
          });
      })

      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Erreur',
          text: err,
        });
        setOpenbackdrop(false);
        console.log(err);
      });
  };

  const createHistory = () => {
    axios.post(
      `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/session_url/set_object_from_session_url`,
      {
        id: sessionStorage.getItem('id_prospect') + Date.now(),
        objet: {
          proposition: proposition,
          customerInfo: customerInformations,
          souscripteur: souscripteur,
        },
      }
    );
  };
  function valide() {
    if (isValidBic() === false) {
      setOpenBic(true);
    } else if (isValidIBan(souscripteur[0]?.iban) === false) {
      setOpenIban(true);
    } else {
      subscribeHandler();
      createHistory();
    }
  }
  const handleCloseBic = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenBic(false);
  };
  const handleCloseIban = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenIban(false);
  };

  return (
    <>
      <div className={classes.container}>
        <Row className={classes.titleRow}>
          <Col className={classes.titleCol}>
            <h3 className={classes.title}>Informations Bancaires</h3>
          </Col>
        </Row>
        <Row
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Form className={classes.form}>
            {rows.map((col, index) => {
              return (
                <Row key={index}>
                  {col.map((val, idx) => {
                    return (
                      <InputComponent
                        key={idx}
                        label={val.label}
                        value={val.value || ''}
                        disabled={val.disabled}
                        name={val.name}
                        type={val.type}
                        required={val.required}
                      />
                    );
                  })}
                </Row>
              );
            })}
          </Form>
          <Row>
            <Col className={classes.RetourCol}>
              <Button
                className={classes.retourButton}
                onClick={() => navigate('/Proposition')}
              >
                {' '}
                <FontAwesomeIcon
                  style={{ marginRight: '0.5rem' }}
                  icon={faArrowLeft}
                />
                Retour
              </Button>
            </Col>
            <Col>
              <Button className={classes.confirmButton} onClick={valide}>
                Confirmer{' '}
                <FontAwesomeIcon
                  style={{ marginLeft: '0.5rem' }}
                  icon={faArrowRight}
                />
              </Button>
            </Col>
          </Row>
        </Row>
      </div>
      <Snackbar open={openBic} autoHideDuration={3000} onClose={handleCloseBic}>
        <Alert onClose={handleCloseBic} severity='error' sx={{ width: '100%' }}>
          Veuillez vérifier votre bic
        </Alert>
      </Snackbar>
      <Snackbar
        open={openIban}
        autoHideDuration={3000}
        onClose={handleCloseIban}
      >
        <Alert
          onClose={handleCloseIban}
          severity='error'
          sx={{ width: '100%' }}
        >
          Veuillez vérifier votre iban
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openbackdrop}
        /* onClick={handleCloseBackdrop} */
      >
        <div className={classes.loaderContainer}>
          <Box sx={{ width: '100%' }}>
            <CircularProgress style={{ width: '4rem', height: '4rem' }} />
          </Box>
          <h2>Veuillez patienter un instant.</h2>
        </div>
      </Backdrop>
    </>
  );
}

export default InformationsBancaires;
